<script lang="ts">
  import 'normalize.css';
  import { browser } from '$app/environment';
  import { onMount } from 'svelte';
  import { QueryClient, QueryClientProvider } from '@tanstack/svelte-query';
  import ErrorToast from '$lib/components/Toaster/index.svelte';
  import { showErrorToast } from '$lib/components/Toaster/stores';
  import { oneMinuteInMs } from '$lib/constants';
  import { showPaywallErrorIds, showWarningErrorIds } from '$lib/components/WarningModal';
  import WarningModal from '$lib/components/WarningModal/index.svelte';
  import { showPaywallModal, showWarning } from '$lib/components/WarningModal/stores';
  import { ApiError } from '$lib/api/errors';
  import CookieBanner from '$lib/components/CookieBanner.svelte';
  import MainMenu from '$lib/components/MainMenu.svelte';
  import PaywallModal from '$lib/components/WarningModal/PaywallModal.svelte';

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: browser,
        gcTime: oneMinuteInMs,
        staleTime: oneMinuteInMs,
        retry: (failureCount: number, error: Error | ApiError) => {
          return 'status' in error && error.status >= 500 && failureCount < 2;
        }
      },
      mutations: {
        onError: (error: Error | ApiError) => {
          if ('id' in error && error.id) {
            if (showWarningErrorIds.has(error.id)) {
              showWarning(error);
            } else if (showPaywallErrorIds.has(error.id)) {
              showPaywallModal();
            }
          } else {
            showErrorToast(error);
          }
        }
      }
    }
  });

  onMount(async () => {
    window.addEventListener('unhandledrejection', (event) => {
      showErrorToast(event.reason);
    });
    window.dispatchEvent(new Event('FirstMountEvent'));
  });
</script>

<QueryClientProvider client={queryClient}>
  <MainMenu />

  <slot />

  <footer class="footer mt-auto py-3 bg-light">
    <div class="container">
      <div class="row text-center">
        <div class="offset-md-2 col-md-8">
          <p class="text-muted">
            We're busy building antiquoted, please check back soon for more functionality. If you
            noticed any bugs, please help us squash them by reaching out to <a
              href="mailto:katie@antiquoted.com?subject=I found a bug">katie@antiquoted.com.</a
            >
          </p>
          <p class="text-muted">
            <a target="_blank" href="https://antiquoted.com/privacy-policy/">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
  <CookieBanner />

  <ErrorToast />
  <WarningModal />
  <PaywallModal />
</QueryClientProvider>

<style>
  .footer > div {
    min-height: 5em;
  }
</style>
